@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterLight.ttf");
  font-family: "Inter";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MontserratBold.ttf");
  font-family: "Montserrat";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MontserratRegular.ttf");
  font-family: "Montserrat";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MontserratItalic.ttf");
  font-family: "Montserrat";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MontserratBoldItalic.ttf");
  font-family: "Montserrat";
  font-weight: 700;
}
